<template>
    <div class="share-job-box">
        <div class="header-hint">
            <div class="hint-list" v-if="scrollList.length">
                <ul ref="scroll" :style="{'left':left+'px'}">
                    <li v-for="(item,index) in scrollList" :key="index">{{item}}</li>
                </ul>
            </div>
            <div v-else></div>
            <div class="hint-btn" @click="ListDrawPrizeResFun">我的奖励</div>
        </div>
        <img class="share-title" src="~static/share/title.png" alt="工单总动员">
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div class="share-list">
            <p class="share-tips">本次活动不限次数</p>
            <div class="share-item">
                <h3>邀请好友注册认证</h3>
                <div class="share-jl">
                    <div>奖励<span>￥15</span></div>
                    <button @click="$router.push('/prizeDetail')">邀请</button>
                </div>
            </div>
            <div class="share-item">
                <h3>邀请好友投标工单</h3>
                <div class="share-jl">
                    <div>奖励<span>￥20</span></div>
                    <button @click="goShareJob">去分享</button>
                </div>
            </div>
        </div>
        <div class="share-rules">
            <ol>
                <li>1、邀请奖励仅限通过分享链接邀请好友有效。</li>
                <li>2、获得邀请奖励需邀请的好友注册并完成工程师认证。</li>
                <li>3、分享工单奖励仅限项目型工单和标准工单有效。（分享自由平台工单无效）</li>
                <li>4、分享工单投标奖励仅限同城工程师投标有效。</li>
                <li>5、分享工单投标奖励可叠加，可分享多人投标可领多份奖励。</li>
                <li>6、奖励金额直接进入佣金余额，请前往我的余额自行提现。</li>
                <li>7、禁止通过作弊邀请非工程师完成认证刷取佣金，若发现则佣金清零，情节严重者封禁账号并保留追究法律责任的权利。</li>
                <li>8、禁止通过邀请只投标不接标的工程师刷取佣金。若发现则佣金清零，情节严重者封禁账号并保留追究法律责任的权利。</li>
                <li>9、本活动最终解释权归工业速派所有。</li>
            </ol>
        </div>
        <br/>
        <van-popup v-model="isRecord" position="bottom" :close-on-click-overlay="false" :safe-area-inset-bottom="true">
            <div class="record-box">
                <h4>中奖纪录</h4>
                <ul class="record-list">
                    <li v-for="(item,index) in DrawPrizeList" :key="index">
                        <span>{{item.WinPrizeTime}}</span>
                        <span>{{item.FriendPhone}}</span>
                        <span>{{item.PrizeName}}</span>
                    </li>
                    <li v-if="!DrawPrizeList.length"><span style="flex: 1;text-align: center;">暂无中奖记录</span></li>
                </ul>
                <div class="fooler-btn">
                    <button @click="isRecord=false">关 闭</button>
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
    import bridge from '@/common/dsbridge.js';
    import {
        InviteWinPrizeScrollList,
        InviteWinPrizeList,
    } from "@/common/api.js";
    export default {
        data(){
            return {
                scrollList: [],
                left: 0,
                timer: null,
                isRecord: false,
                DrawPrizeList: [],


                UserId: 0,
                CompanyId: 0,
            }
        },
        created() {
            this.getUserInfo();
            this.getInviteWinPrizeScrollList()
        },
        mounted() {
            let that = this;
            bridge.register('notifyLoginSuccess', function () {
                that.getUserInfo();
            });
        },
        methods: {
            callLogin(){
                bridge.call('launchLogin');
            },
            //跑马灯
            run() {
                if (!this.$refs.scroll) {
                    return;
                }
                let width = this.$refs.scroll.clientWidth;
                let copy = this.$refs.scroll.innerHTML;
                this.$refs.scroll.innerHTML = copy + copy;
                if (width) {
                    this.timer = setInterval(() => {
                        this.left--;
                        if (this.left < -width) this.left = 0;
                    }, 20);
                }
            },
            async getInviteWinPrizeScrollList() {
                let self = this
                let res = await InviteWinPrizeScrollList()
                self.scrollList = res.Data || []
                if (self.scrollList.length) {
                    self.$nextTick(() => {
                        self.run();
                    })
                }
            },
            // 点击查看中奖纪录
            async ListDrawPrizeResFun() {
                if(!this.UserId && !this.CompanyId){
                    this.callLogin();
                    return
                }
                let data = {}
                // data.UserId = 3865265
                data.UserId = this.UserId
                data.CompanyId = this.CompanyId
                let res = await InviteWinPrizeList(data)
                if (res.Result != 1) {
                    return this.$toast(res.Message);
                }
                this.isRecord = true
                this.DrawPrizeList = res.Data || []
            },
            goShareJob(){
                bridge.call('jumpJobPool')
            },
            getUserInfo(){
                let that = this;
                let info = bridge.call('getUserInfo') || null;
                if (!info) return
                let getUserInfoApp = JSON.parse(info);
                let userCode = getUserInfoApp.userId;
                if (!getUserInfoApp.isLogin) {
                    // that.callLogin();
                    return;
                }
                if (userCode.indexOf('p') === 0) {
                    that.UserId = Number(userCode.substr(1));
                } else if (userCode.indexOf('e') === 0) {
                    that.CompanyId = Number(userCode.substr(1));
                }
            },
        }
    }
</script>
<style lang="scss">
    @import '~@/style/base.scss';
    
    .share-job-box {
        width: 10rem;
        margin: 0 auto;
        min-height: 100vh;
        background-size: contain;
        background-color: #3939d9;
        background-position: center top;
        @include bg('~static/share/share-bg.png');

        .header-hint {
            padding-top: px2rem(28px);
            padding-left: px2rem(28px);
            display: flex;
            align-items: center;
            justify-content: space-between;

            .hint-list {
                color: #FFFFFF;
                width: px2rem(510px);
                height: px2rem(48px);
                font-size: px2rem(24px);
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: normal;
                border-radius: px2rem(24px);
                background-color: rgba(0,0,0,.3);
                white-space: nowrap;
                padding: 0 px2rem(20px);
                box-sizing: border-box;
                position: relative;
                overflow: hidden;

                ul {
                    overflow: hidden;
                    position: absolute;
                    left: 0;
                    top: 0;
                    line-height: px2rem(48px);
                    white-space: nowrap;

                    li {
                        padding-left: px2rem(20px);
                        display: inline-block;
                        vertical-align: top;
                        padding-right: px2rem(14px);
                    }
                }
            }

            .hint-btn {
                color: #ffffff;
                text-align: center;
                width: px2rem(154px);
                height: px2rem(48px);
                font-size: px2rem(24px);
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: normal;
                padding-left: px2rem(18px);
                background-color: #1818A1;
                border-radius: px2rem(24px) 0 0 px2rem(24px);
            }
        }
        .record-box {
            position: relative;
            padding-bottom: px2rem(240px);
            // height: px2rem(655px);

            h4 {
                font-weight: 500;
                color: #333333;
                text-align: center;
                font-size: px2rem(32px);
                line-height: px2rem(120px);
            }

            .record-list {
                height: px2rem(360px);
                overflow-y: scroll;

                li {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: px2rem(90px);
                    padding: 0 px2rem(30px);
                    border-bottom: 1px solid #e5e5e5;
                }
            }

            .fooler-btn {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: px2rem(80px) px2rem(50px);

                button {
                    display: block;
                    color: #ffffff;
                    border-radius: 2px;
                    width: 100%;
                    height: px2rem(80px);
                    background: #F7781C;
                    font-size: px2rem(28px);
                }
            }
        }

        .share-title {
            display: block;
            width: px2rem(598px);
            height: px2rem(120px);
            margin: px2rem(74px) auto;
        }
        
        .share-list {
            padding: 0 px2rem(34px);
            .share-tips {
                text-align: right;
                font-size: px2rem(24px);
                color: #ffffff;
                line-height: px2rem(76px);
            }
            .share-item {
                background: #7C69FF;
                min-height: px2rem(180px);
                border-radius: px2rem(20px);
                margin-bottom: px2rem(40px);
                box-shadow: 0px 6px 12px 1px rgba(0, 0, 0, 0.08);
                padding: px2rem(30px) px2rem(20px);
                h3 {
                    color: #ffffff;
                    font-size: px2rem(34px);
                }
                .share-jl {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    > div {
                        color: #FEBD2C;
                        font-size: px2rem(30px);
                        span {
                            font-size: px2rem(50px);
                        }
                    }
                    > button {
                        color: #3939D9;
                        height: px2rem(68px);
                        width: px2rem(218px);
                        background: #FEC10E;
                        font-size: px2rem(30px);
                        font-weight: 500;
                        border-radius: px2rem(34px);
                    }
                }
            }
        }
        .share-rules {
            color: #ffffff;
            font-size: px2rem(24px);
            line-height: px2rem(36px);
            padding: px2rem(50px) px2rem(28px) px2rem(100px);
        }
    }
</style>